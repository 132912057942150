:root {
  
  --off-white-color: rgba(255, 245, 238, 1);
  --specific-blue-color: rgba(73, 99, 255, 1);
  
  --header-height: 80px;
  --header-height-column: calc(var(--header-height) * 1.5);
  --header-height-mobile: calc(var(--header-height) * 1.25);
  --nav-button-padding: 50px;
  --footer-padding: 15px;
  
  --section-container-padding: 80px 40px 80px 40px; 
  --section-container-padding-mobile: 60px 40px 40px 40px; 
  
  --about-text-font-size: 1.25em;
  --about-img-margin: 2vw 0px 2vw 0px;
  --about-download-panel-margin: 3vw;
  
  --service-panel-margin: 25px;
  --service-border-radius: 0px;
  
  --contact-person-panel-margin: 2vw;
  --contact-map-size: 360px;
  --contact-map-size-small: 300px;
}

@font-face {
  
  font-family: bebas;
  src: url('./fonts/bebas-neue/bebas-neue.ttf');
}

@font-face {
  
  font-family: open-sans;
  src: url('./fonts/open-sans/OpenSans-Regular.ttf');
}

@font-face {
  
  font-family: open-sans-light;
  src: url('./fonts/open-sans/OpenSans-Light.ttf');
}

@font-face {
  
  font-family: tw-cen;
  src: url('./fonts/tw-cen-mt-condensed/tw-cen-mt.ttf');
}

* {
  
  margin: 0px;
  padding: 0px;
  
  font-size: 14px;
}

body::-webkit-scrollbar {
  
  display: none;
}

body {
  
  font-family: open-sans;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bold {
  
  font-weight: bold;
}

.header-main {
  
  height: var(--header-height);
  width: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;

  background: rgba(255, 255, 255, 1);
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  
  z-index: 1;
}

.header-logo-container {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  height: 100%;
  
}

.header-logo {
  
  margin-left: 20px;
  
  height: 80%;
  width: auto;
}

.nav-buttons {
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  height: 100%;
}

.nav-button {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  height: 100%;
  width: 200px;
  
  font-size: 1.8em;
  font-family: bebas;
  text-decoration: none;
  
  color: black;
}

.footer-main {
  
  width: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  
  background: rgba(255, 255, 255, 1);
  
  padding-bottom: 100px;
  
  border-top-style: solid;
  border-top-color: black;
  border-top-width: 1px;
  
  overflow-x: hidden;
  
  font-family: open-sans-light;
}

.footer-left {
  
  margin: var(--footer-padding);
  white-space: nowrap;
}

.footer-link {
  
  
}

.footer-right {
  
  margin: var(--footer-padding);
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  
  text-align: right;
}

.content-container {
  
  margin-top: var(--header-height);
  
  padding: var(--section-container-padding);
  
  background: var(--off-white-color);
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content-section {
  
  margin-top: 20px;
  margin-bottom: 20px;
}

/*********************************************
PAGE: SPLASH
**********************************************/

.splash-image-container {
  
  width: 100%;
  height: 100%;
  
  background-image: url('./images/sample.jpg');
  background-size: cover; 
  background-position: center;
}

.splash-overlay {
  
  position: absolute;
  
  top: 0;
  left: 0;
  
  width: 600px;
  height: 100%;
  
  background: rgba(0, 0, 0, 0.5);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-overlay-content {
  
  margin-top: var(--header-height);
  
  width: 100%;
  padding-left: 100px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.splash-slogan {
  
  width: 75%;
  
  font-family: bebas;
  font-size: 3em;
  
  text-align: left;
  
  color: white;
  
  margin-bottom: 25px;
}

.splash-button {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  color: white;
  text-decoration: none;
  
  font-size: 1.75em;
  font-family: open-sans-light;
  
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  
  background: var(--specific-blue-color);
}

/*********************************************
PAGE: ABOUT
**********************************************/

.about-info-section {
  
  width: 80vw;
  padding-bottom: 4vw;
  
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.5);
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-info-one {
  
  font-size: 1.5em;
  
  width: 60%;
  
  text-align: center;
  
}

.about-info-one > .company-name {
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-family: bebas;
  font-size: 1.75em;
  font-weight: bold;
  
  margin-bottom: 1vw;
}

.about-large-img {
  
  background-image: url("./images/about1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  margin: var(--about-img-margin);
  
  height: 500px;
  width: 100%;
}

.about-info-two {
  
  padding: 40px;
  
  font-size: 1.25em;
  
  background: rgba(255, 255, 255, 1);
}

.about-gallery {
  
  margin: var(--about-img-margin);
  height: 25vw;
  width: 100%;
  
  display: grid;
  grid-gap: 1vw;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  justify-items: stretch;
  align-items: stretch;
}

.about-gallery-img {
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-gallery-one {
  
  background-image: url(./images/about2.jpg);
}

.about-gallery-two {
  
  background-image: url(./images/about3.jpg);
}

.about-gallery-three {
  
  background-image: url(./images/about4.jpg);
}

.about-info-three {
  
  display: grid;
  
  grid-gap: 2vw;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "abt-left abt-right";
  
  justify-items: stretch;
  align-items: stretch;
}

.about-small-info {
  
  padding: 40px;
  
  font-size: 1.25em;
  
  background: rgba(255, 255, 255, 1);
}

.about-left {
  
  grid-area: abt-left;
}

.about-right {
  
  grid-area: abt-right;
}

.about-downloads-section {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-downloads-header {
  
  font-family: bebas;
  font-size: 3em;
  
  margin: 2vw 0px 4vw 0px;
}

.about-downloads-grid {
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.about-download-panel {
  
  //border-style: solid;
  //border-width: 1px;
  //border-color: black;
  
  width: 12vw;
  height: 12vw;
  
  padding: 40px;
  
  margin-left: var(--about-download-panel-margin);
  margin-right: var(--about-download-panel-margin);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  background: rgba(255, 255, 255, 1);
  
  cursor: pointer;
  
  border-radius: 50%;
}

.about-download-panel > img {
  
  height: 6vw;
  width: 6vw;
  
  margin-bottom: 2vw;
}

.about-download-panel > p {
  
  font-weight: bold;
  font-size: 1.25vw;
  
  text-align: center;
}

.about-download-confirm-panel {
  
  z-index: 2;
  
  width: 100%;
  height: 100%;
  
  position: fixed;
  
  top: 0;
  left: 0;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  transition-property: all;
  transition-duration: 0.5s;
}

.about-download-overlay {
  
  position: absolute;
  
  background: rgba(0, 0, 0, 0.5);
  
  width: 100%;
  height: 100%;
}

.about-download-hidden {
  
  top: -100%;
}

.about-download-shown {
  
  top: 0;
}

.about-download-confirm-form {
  
  position: relative;
  
  z-index: 3;
  
  padding: 40px 20px 40px 20px;
  
  width: 300px;
  
  border-radius: 25px;
  
  background: rgba(255, 255, 255, 1);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-download-confirm-header {
  
  text-align: center;
  
  margin-bottom: 20px;
  
  text-transform: uppercase;
  
  font-family: bebas;
  font-size: 1.75em;
}

.about-download-confirm-label {
  
  font-family: open-sans;
}

.about-download-confirm-textfield {
  
  margin: 20px 0px 5px 0px;
  padding: 10px;
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.about-download-confirm-error {
  
  color: red;
  padding-bottom: 15px;
  
  transition-property: opacity;
  transition-duration: 0.25s;
}

.about-download-confirm-error-shown {
  
  opacity: 1;
}

.about-download-confirm-error-hidden {
  
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.about-download-confirm-button {
  
  margin-top: 20px;
  
  padding: 10px;
  
  background: var(--specific-blue-color);
  
  border-radius: 5px;
  border-style: none;
  
  cursor: pointer;
  
  color: white;
}

.about-download-confirm-submitted {
  
  background: rgba(64, 255, 128, 1);
}

.about-download-confirm-unsubmitted {
  
  background: var(--specific-blue-color);
}

.about-download-confirm-close {
  
  position: absolute;
  
  right: 20px;
  top: 15px;
  
  font-weight: bold;
  font-size: 1.25em;
  
  cursor: pointer;
}

/*********************************************
PAGE: SERVICES
**********************************************/

.services-section {
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.section-header {
  
  font-family: bebas;
  font-size: 3em;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  text-align: center;
}

.service-divider {
  
  width: 90%;
  height: 1px;
  
  margin: 40px 0 40px 0;
  
  background: rgba(0, 0, 0, 0.4);
}

.service-panel {
  
  width: 100%;
  background: rgba(255, 255, 255, 1);
  color: black;
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  border-radius: var(--service-border-radius);
  border-style: solid;
  border-width: 1px;
  
  margin-top: var(--service-panel-margin);
  margin-bottom: var(--service-panel-margin);
  
}

.service-panel-normal {
  
  grid-template-areas:
    "srv-info srv-img";
}

.service-panel-reverse {
  
  grid-template-areas:
    "srv-img srv-info";
}

.service-info-panel {
  
  padding: 40px;
  
  grid-area: srv-info;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.service-img {
  
  grid-area: srv-img;
  
  min-height: 400px;
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.service-img-left {
  
  border-top-left-radius: var(--service-border-radius);
  border-bottom-left-radius: var(--service-border-radius);
}

.service-img-right {
  
  border-top-right-radius: var(--service-border-radius);
  border-bottom-right-radius: var(--service-border-radius);
}

.service-sample-img {
  
  background-image: url(./images/sample.jpg);
}

.service-structural-img 
{
  background-image: url(./images/structural-steelworks.jpg);
}

.service-electro-img 
{
  background-image: url(./images/electro-mechanical-works.jpg);
}

.service-civil-img 
{
  background-image: url(./images/civil-architectural-works.jpg);
}

.service-industrial-img 
{
  background-image: url(./images/sample.jpg);
}

.service-header {
  
  font-family: bebas;
  font-size: 1.75em;
  
  align-self: flex-start;
}

.service-lines {
  
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.service-line {
  
  font-size: 1.25em;
  text-align: left;
}

/*********************************************
PAGE: CONTACT
**********************************************/

.contact-person-section {
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  margin-top: 50px;
  margin-bottom: 100px;
}

.contact-person {
  
  width: 220px;
  height: 325px;
  
  padding: 20px;
  
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  
  margin-left: var(--contact-person-panel-margin);
  margin-right: var(--contact-person-panel-margin);
  
  background: rgba(255, 255, 255, 1);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.contact-portrait {
  
  width: 125px;
  height: 125px;
  
  border-radius: 50%;
  
  background: rgba(0, 0, 0, 0.5);
  
  margin-bottom: 35px;
}

.contact-info {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  text-align: center;
  
  font-size: 1em;
}

.address-section {
  
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.5);
  
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.5);
  
  padding-top: 50px;
  padding-bottom: 50px;
  
  display: grid;
  grid-gap: 40px;
  grid-template-areas: 
    " . . . m-head m-head m-dir m-dir . . . "
    " . . . m-addr m-addr m-dir m-dir . . . "
    " . . . m-addr m-addr m-dir m-dir . . . ";
  justify-items: center;
  align-items: center;
}

.map-info {
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.map-info-header {
  
  grid-area: m-head;
  
  font-family: bebas;
  font-size: 3.5em;
  
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: row;
  align-items: center;
}

.map-address {
  
  grid-area: m-addr;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  
  font-size: 1.25em;
  
  width: 100%;
  height: 100%;
}

.map-container {
  
  grid-area: m-dir;
  
  width: var(--contact-map-size);
  height: var(--contact-map-size);
  
  margin: 15px 0px 15px 15px;
  
  border-radius: 20px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(./images/directions.png);
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  
  justify-self: end;
}

.submit-message-section
{
  padding-top: 50px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 80%;
}

.submit-message-form
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit-message-label
{
  display: inline-block;
  width: 150px;
  
  font-family: open-sans;
}

.submit-message-names-panel
{
  display: flex;
  flex-direction: row;
}

.submit-message-contact-info-panel
{
  display: flex;
  flex-direction: row;
}

.submit-message-info-panel
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  width: 250px;
  
  margin: 15px;
}

.submit-message-label
{
  width: 100%;
  
  margin-bottom: 10px;
}

.submit-message-textfield
{
  width: 100%;
  
  padding: 5px;
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.submit-message-error 
{
  color: red;
}

.submit-message-content-panel
{
  margin: 15px;
  width: 95%;
}

.submit-message-button
{
  margin-top: 25px; 
  padding: 20px 40px 20px 40px;
  
  border-radius: 10px;
  
  cursor: pointer;
  
  color: white;
}

.submit-message-button-submitted {
  
  background: rgba(64, 255, 128, 1);
}

.submit-message-button-unsubmitted {
  
  background: var(--specific-blue-color);
}

/*********************************************
Media Query: Max 1200px
**********************************************/

@media (max-width: 1200px) {
  
  .header-main {
    
    height: var(--header-height-column);
    flex-direction: column;
  }
  
  .header-logo-container {
    
    height: calc(var(--header-height-column) * 0.6);
  }
  
  .header-logo {
    
    margin-left: 0px;
  }
  
  .nav-buttons {
    
    height: calc(var(--header-height-column) * 0.4);
    width: 100%;
  }
  
  .nav-buttons > a {
   
    font-size: 1.35em;
  }
  
  .about-info-section {
    
    width: 90vw;
  }
}

/*********************************************
Media Query: Max 1024px
**********************************************/

@media (max-width: 1024px) {
  
  .contact-person 
  {
    width: 175px;
    height: 300px;
    
    padding: 20px;
    
    word-wrap: normal;
  }
  
  .contact-info {
    
    font-size: 0.8em;
  }
  
  .address-section {
    
    grid-template-areas: 
      " . m-head m-head m-dir m-dir . "
      " . m-addr m-addr m-dir m-dir . "
      " . m-addr m-addr m-dir m-dir . ";
  }
}

/*********************************************
Media Query: Max 768px
**********************************************/

@media (max-width: 768px) {
  
  .splash-overlay {
    
    width: 100%;
  }
  
  .splash-overlay-content {
    
    padding-left: 0px;
    align-items: center;
  }
  
  .splash-slogan {
    
    font-size: 2.5em;
    text-align: center;
  }
  
  .splash-button {
    
    font-size: 1.25em;
  }
  
  .about-info-one {
    
    font-size: 1.25em;
  }
  
  .about-info-one > .company-name {
    
    font-size: 1.5em;
  }
  
  .about-info-three {
    
    grid-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
      "abt-left"
      "abt-right";
    
    justify-items: baseline;
    align-items: baseline;
  }
  
  .about-download-panel {
    
    width: 15vw;
    height: 15vw;
    
    margin: 10px;
  }
  
  .about-download-panel > img {
    
    width: 8vw;
    height: 8vw;
  }
  
  .about-download-panel > p {
    
    font-size: 1.85vw;
  }
  .service-panel {
    
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
  }
  
  .service-panel-normal {
    
    grid-template-areas:
      "srv-img"
      "srv-info";
  }
  
  .service-panel-reverse {
    
    grid-template-areas:
      "srv-img"
      "srv-info";
  }
  
  .service-info-panel {
    
    padding: 5vw;
  }
  
  .service-divider {
    
    margin: 2vw 0px 2vw 0px;
  }
  
  .service-img {

    grid-area: srv-img;

    min-height: 50vw;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .service-header {
    
    font-size: 1.25em;
  }
  
  .contact-person-section {
    
    flex-direction: column;
    
    margin-bottom: 25px;
  }
  
  .contact-person {
    
    width: 420px;
    height: 180px;
    
    flex-direction: row;
    
    margin-bottom: 40px;
  }
  
  .contact-portrait {
    
    margin-bottom: 0px;
    margin-right: 35px;
    margin-left: 20px;
  }
  
  .contact-info {
    
    flex-grow: 1;
    font-size: 1em;
  }
  
  .address-section {
    
    width: 75%;
    
    grid-template-areas:
      "m-head"
      "m-addr"
      "m-dir";
  }
  
  .map-info {
    
    justify-content: center;
    align-items: center;
  }
  
  .map-info-header {
    
    justify-content: center;
  }
  
  .map-address {
    
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  
  .map-container {
    
    margin: 0px;
    
    justify-self: auto;
  }
  
  .submit-message-names-panel
  {
    display: flex;
    flex-direction: column;
  }

  .submit-message-contact-info-panel
  {
    display: flex;
    flex-direction: column;
  }
  
}

/*********************************************
Media Query: Max 480px
**********************************************/

@media (max-width: 480px) {

  .header-main {
    
    height: var(--header-height-mobile);
  }
  
  .header-logo-container {
    
    height: calc(var(--header-height-mobile) * 0.6);
  }
  
  .header-logo {

    height: auto;
    width: 60%;
  }
  
  .nav-buttons {
    
    height: calc(var(--header-height-mobile) * 0.4);
    width: 100%;
  }
  
  .nav-buttons > a {
   
    font-size: 1.125em;
  }
  
  .footer-left > p
  {
    font-size: 0.75em;
  }
  
  .footer-right > p
  {
    font-size: 0.75em;
  }
  
  .content-container {
    
    padding: var(--section-container-padding-mobile);
  }
  
  .about-info-one {
    
    width: 90%;
    
    margin-bottom: 20px;
  }
  
  .about-large-img {
    
    height: 250px;
  }
  
  .about-gallery {
    
    height: 250px;
    
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  
  .about-gallery-two {
    
    display: none;
  }
  
  .about-gallery-three {
    
    display: none;
  }
  
  .about-downloads-grid {
    
    flex-wrap: wrap;
  }
  
  .about-download-panel {
    
    width: 40vw;
    height: 15vw;
    
    border-radius: 15px;
  }
  
  .about-download-panel > img {
    
    height: 9vw;
    width: 9vw;
  }
  
  .about-download-panel > p {
    
    font-size: 3vw;
  }
  
  .contact-person-section {
    
    margin-top: 20px;
  }
  
  .contact-person {
    
    width: 275px;
    height: 125px;
    
    margin-bottom: 25px;
  }
  
  .contact-portrait {
    
    margin: 0px;
    
    width: 100px;
    height: 100px;
  }
  
  .contact-info {
    
    font-size: 0.75em;
    
    margin-left: 20px;
    
    align-items: flex-start;
    text-align: left;
    
    width: 50%;
  }
  
  .contact-info > a {
    
    font-size: 1em;
  }
  
  .address-section {
    
    width: 100%;
  }
  
  .map-info-header {
    
  }
  
  .map-address {
    
    font-size: 1em;
  }
  
  .map-container {
    
    width: var(--contact-map-size-small);
    height: var(--contact-map-size-small);
  }
}